import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/proscom/hse-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { FileInput } from '@hse-design/react';
import { ComponentLinks, FigmaEmbed } from '../common';
import { Playground, Props } from 'docz';
import { useState, useEffect, useRef } from 'react';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "fileinput"
    }}>{`FileInput`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`import { FileInput } from '@hse-design/react';
`}</code></pre>
    <ComponentLinks figma={'https://www.figma.com/file/cBHD57QcCt9WDT4e7e2B3w/hse_general_library?node-id=6721%3A15978'} storybook={'/?path=/story/inputs-fileinput--demo-ajax'} vue={'/components/HseFileInput/HseFileInput.html'} vueStorybook={'/?path=/story/inputs-fileinput--demo-ajax'} mdxType="ComponentLinks" />
    <h2 {...{
      "id": "описание"
    }}>{`Описание`}</h2>
    <p>{`FileInput – это компонент для загрузки файлов.
Компонент можно использовать как замену `}<inlineCode parentName="p">{`<input type="file" />`}</inlineCode>{` в обычных синхронных формах,
так и отдельно для асинхронной загрузки файлов.`}</p>
    <p>{`При перетаскивании файла на окно браузера, у компонента появляется дропзона. Она может быть отключена передачей пропа `}<inlineCode parentName="p">{`disableDropzone`}</inlineCode>{`.`}</p>
    <p>{`При выборе или перетаскивании файла вызывается колбек `}<inlineCode parentName="p">{`onFileSelect`}</inlineCode>{`, в который единственным аргументом передается
`}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/API/FileList"
      }}><inlineCode parentName="a">{`FileList`}</inlineCode></a>{`.`}</p>
    <p>{`Компонент имеет три размера: `}<inlineCode parentName="p">{`small`}</inlineCode>{`, `}<inlineCode parentName="p">{`medium`}</inlineCode>{`, `}<inlineCode parentName="p">{`large`}</inlineCode>{`. Размер передается в проп `}<inlineCode parentName="p">{`size`}</inlineCode>{`.`}</p>
    <p>{`Задать ширину компонента можно с помощью атрибута `}<inlineCode parentName="p">{`style`}</inlineCode>{`, например:`}</p>
    <pre><code parentName="pre" {...{}}>{`style={{ width: '190px' }}
`}</code></pre>
    <h2 {...{
      "id": "анатомия"
    }}>{`Анатомия`}</h2>
    <FigmaEmbed node="?node-id=9534:32488" mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "примеры"
    }}>{`Примеры`}</h2>
    <h3 {...{
      "id": "использование-в-синхронной-форме"
    }}>{`Использование в синхронной форме`}</h3>
    <p>{`Компонент можно использовать как замену `}<inlineCode parentName="p">{`<input type="file" />`}</inlineCode>{` в обычных синхронных формах,
когда отправка файла происходит одновременно с отправкой всей формы`}</p>
    <p>{`Для корректной работы такого варианта не передавайте пропы `}<inlineCode parentName="p">{`fileName`}</inlineCode>{` и `}<inlineCode parentName="p">{`onRemove`}</inlineCode>{`.`}</p>
    <p>{`Для отображения ошибки можно использовать проп `}<inlineCode parentName="p">{`errorMessage`}</inlineCode>{`.`}</p>
    <Playground __position={2} __code={'<div style={{ padding: \'24px 48px\' }}>\n  <FileInput\n    name=\"userFile\"\n    style={{ width: \'190px\' }}\n    errorMessage=\"\"\n    hint=\"Прикрепите файл резюме\"\n  />\n</div>'} __scope={{
      props,
      DefaultLayout,
      FileInput,
      ComponentLinks,
      FigmaEmbed,
      Playground,
      Props,
      useState,
      useEffect,
      useRef,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div style={{
        padding: '24px 48px'
      }}>
    <FileInput name="userFile" style={{
          width: '190px'
        }} errorMessage="" hint="Прикрепите файл резюме" mdxType="FileInput" />
  </div>
    </Playground>
    <h3 {...{
      "id": "использование-в-асинхронной-форме"
    }}>{`Использование в асинхронной форме`}</h3>
    <p>{`Если интерфейс предполагает, что файл загружается отдельно от остальных данных формы,
сразу после его выбора пользователем, то компонент необходимо использовать следующим образом:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Реализуйте колбек `}<inlineCode parentName="p">{`onFileSelect`}</inlineCode>{`, и инициализируйте в нём загрузку файла на сервер (в примере ниже загрузка симулируется через `}<inlineCode parentName="p">{`setInterval`}</inlineCode>{`).`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Когда файл загружен, сохраните необходимые данные об этом файле (например, `}<inlineCode parentName="p">{`id`}</inlineCode>{`) в какой-нибудь стейт (в примере используется локальный стейт `}<inlineCode parentName="p">{`file`}</inlineCode>{`)`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Реализуйте колбек `}<inlineCode parentName="p">{`onRemove`}</inlineCode>{`, в котором очистите ваш стейт и сбросьте значение инпута, вызвав метод `}<inlineCode parentName="p">{`clear()`}</inlineCode>{` у инстанса компонента `}<inlineCode parentName="p">{`FileInput`}</inlineCode>{` через реф.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`В процессе загрузки файла вы можете передавать проп `}<inlineCode parentName="p">{`progress`}</inlineCode>{`, равный проценту загрузки. Можно использовать строковое значение `}<inlineCode parentName="p">{`"indeterminate"`}</inlineCode>{`, если процент загрузки неизвестен.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Если в процессе загрузки файла возникает ошибка, то используйте проп `}<inlineCode parentName="p">{`errorMessage`}</inlineCode>{` для ее отображения.`}</p>
      </li>
    </ol>
    <Playground __position={3} __code={'() => {\n  const [errorMessage, setErrorMessage] = useState(\'\')\n  const [file, setFile] = useState(undefined)\n  const [progress, setProgress] = useState(false)\n  const ref = useRef()\n  const intervalRef = useRef()\n  const onFileSelect = files => {\n    if (!files.length) {\n      setErrorMessage(\'Файл не выбран\')\n      return\n    }\n    setErrorMessage(\'\')\n    setProgress(0)\n    // Запускаем загрузку файла на сервер\n    clearInterval(intervalRef.current)\n    let counter = 0\n    intervalRef.current = setInterval(() => {\n      counter += 10\n      if (counter >= 100) {\n        // Файл загружен на сервер\n        setFile(files[0])\n        setProgress(false)\n        clearInterval(intervalRef.current)\n      } else {\n        setProgress(counter)\n      }\n    }, 500)\n  }\n  const onRemove = () => {\n    // Сбрасываем локальный стейт и значение инпута и отменяем загрузку\n    setFile(undefined)\n    setProgress(false)\n    ref.current.clear()\n    clearInterval(intervalRef.current)\n  }\n  useEffect(() => () => clearInterval(intervalRef.current), [])\n  return (\n    <div style={{ padding: \'24px 48px\' }}>\n      <FileInput\n        ref={ref}\n        fileName={file ? file.name : \'\'}\n        errorMessage={errorMessage}\n        canRemove={!!file || progress !== false}\n        progress={progress}\n        onFileSelect={onFileSelect}\n        onRemove={onRemove}\n        style={{ width: \'190px\' }}\n      />\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      FileInput,
      ComponentLinks,
      FigmaEmbed,
      Playground,
      Props,
      useState,
      useEffect,
      useRef,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [errorMessage, setErrorMessage] = useState('');
        const [file, setFile] = useState(undefined);
        const [progress, setProgress] = useState(false);
        const ref = useRef();
        const intervalRef = useRef();

        const onFileSelect = files => {
          if (!files.length) {
            setErrorMessage('Файл не выбран');
            return;
          }

          setErrorMessage('');
          setProgress(0); // Запускаем загрузку файла на сервер

          clearInterval(intervalRef.current);
          let counter = 0;
          intervalRef.current = setInterval(() => {
            counter += 10;

            if (counter >= 100) {
              // Файл загружен на сервер
              setFile(files[0]);
              setProgress(false);
              clearInterval(intervalRef.current);
            } else {
              setProgress(counter);
            }
          }, 500);
        };

        const onRemove = () => {
          // Сбрасываем локальный стейт и значение инпута и отменяем загрузку
          setFile(undefined);
          setProgress(false);
          ref.current.clear();
          clearInterval(intervalRef.current);
        };

        useEffect(() => () => clearInterval(intervalRef.current), []);
        return <div style={{
          padding: '24px 48px'
        }}>
        <FileInput ref={ref} fileName={file ? file.name : ''} errorMessage={errorMessage} canRemove={!!file || progress !== false} progress={progress} onFileSelect={onFileSelect} onRemove={onRemove} style={{
            width: '190px'
          }} mdxType="FileInput" />
      </div>;
      }}
    </Playground>
    <h3 {...{
      "id": "скачивание-загруженного-файла"
    }}>{`Скачивание загруженного файла`}</h3>
    <p>{`В асинхронном варианте вы можете дать возможность пользователю скачать файл, который он только что загрузил.
Это поможет пользователю лишний раз убедиться, что он загрузил именно тот файл, который собирался.
Для скачивания файла передайте проп `}<inlineCode parentName="p">{`canDownload`}</inlineCode>{` и реализуйте колбек `}<inlineCode parentName="p">{`onDownload`}</inlineCode>{`.`}</p>
    <Playground __position={4} __code={'() => {\n  const [errorMessage, setErrorMessage] = useState(\'\')\n  const [file, setFile] = useState(undefined)\n  const ref = useRef()\n  const onFileSelect = files => {\n    if (!files.length) {\n      setErrorMessage(\'Файл не выбран\')\n      return\n    }\n    setFile(files[0])\n    setErrorMessage(\'\')\n  }\n  const onRemove = () => {\n    // Сбрасываем локальный стейт и значение инпута и отменяем загрузку\n    setFile(undefined)\n    ref.current.clear()\n  }\n  const onDownload = () => {\n    window.open(\'https://www.hse.ru/images/main_en/hse_ru_logo.svg\', \'_blank\')\n  }\n  return (\n    <div style={{ padding: \'24px 48px\' }}>\n      <FileInput\n        ref={ref}\n        fileName={file ? file.name : \'\'}\n        errorMessage={errorMessage}\n        canDownload={!!file}\n        canRemove={!!file}\n        onFileSelect={onFileSelect}\n        onRemove={onRemove}\n        onDownload={onDownload}\n        style={{ width: \'190px\' }}\n      />\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      FileInput,
      ComponentLinks,
      FigmaEmbed,
      Playground,
      Props,
      useState,
      useEffect,
      useRef,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [errorMessage, setErrorMessage] = useState('');
        const [file, setFile] = useState(undefined);
        const ref = useRef();

        const onFileSelect = files => {
          if (!files.length) {
            setErrorMessage('Файл не выбран');
            return;
          }

          setFile(files[0]);
          setErrorMessage('');
        };

        const onRemove = () => {
          // Сбрасываем локальный стейт и значение инпута и отменяем загрузку
          setFile(undefined);
          ref.current.clear();
        };

        const onDownload = () => {
          window.open('https://www.hse.ru/images/main_en/hse_ru_logo.svg', '_blank');
        };

        return <div style={{
          padding: '24px 48px'
        }}>
        <FileInput ref={ref} fileName={file ? file.name : ''} errorMessage={errorMessage} canDownload={!!file} canRemove={!!file} onFileSelect={onFileSelect} onRemove={onRemove} onDownload={onDownload} style={{
            width: '190px'
          }} mdxType="FileInput" />
      </div>;
      }}
    </Playground>
    <h2 {...{
      "id": "пропы"
    }}>{`Пропы`}</h2>
    <Props of={FileInput} mdxType="Props" />
    <p><inlineCode parentName="p">{`className`}</inlineCode>{` и `}<inlineCode parentName="p">{`style`}</inlineCode>{` пробрасываются на корневой элемент `}<inlineCode parentName="p">{`div`}</inlineCode>{`.
Все остальные пропы пробрасываются на элемент `}<inlineCode parentName="p">{`input`}</inlineCode>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      